import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Empty,
  Modal,
  Pagination,
  Popconfirm,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { NewsEditAddModal } from "../../components/newsEditAddModal";
import Search from "antd/es/input/Search";
import { newsDelete, newsGet } from "../../api/api";
import { IMAGE_URL } from "../../axios/constants";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const News = () => {
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  // edit and add modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionModal, setOptionModal] = useState({
    open: false,
    type: "",
    item: {},
  });

  useEffect(() => {
    setLoading(true);
    try {
      newsGet(10, current, date, searchWord)
        .then((res) => {
          setData(res.data?.data?.items);
          setPagination(res?.data?.data?.meta);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, update, optionModal, date, searchWord]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setOptionModal({
      open: false,
    });
  };

  // delete
  const confirm = (id) => {
    try {
      newsDelete(id)
        .then((res) => {
          message.success("Muvaffaqiyatli o'chirildi");
          setUpdate((prev) => !prev);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const cancel = (e) => {
    // console.log(e);
    // message.error('Click on No');
  };

  // filter date
  const onChangeDate = (date, dateString) => {
    console.log(dateString);
    setDate(dateString);
  };

  // on search
  const onSearch = (value) => setsearchWord(value);

  const { size, page, totalCount } = pagination;

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between align-items-center">
          <h3 className="mb-3">Yangiliklar</h3>
          <div className="d-flex align-items-center justify-content-center">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              allowClear
              style={{
                width: 200,
              }}
            />
            <RangePicker
              format={dateFormat}
              onChange={onChangeDate}
              className="mx-2"
            />
            <Button
              type="primary"
              onClick={() => {
                setOptionModal({
                  open: true,
                  type: "add",
                  item: {},
                });
              }}
            >
              + Qo'shish
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "1%" }} scope="col">
                  N
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha uz
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha ru
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha en
                </th>
                <th scope="col">Tasnif uz</th>
                <th scope="col">Tasnif ru</th>
                <th scope="col">Tasnif en</th>
                <th style={{ width: "5%" }} scope="col">
                  Joylashtirilgan vaqti
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Rasm
                </th>
                <th style={{ width: "3%" }} scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="p-5 text-center">
                  <Spin />
                </div>
              ) : data?.length > 0 ? (
                data?.map((item, inx) => (
                  <tr key={item.id}>
                    <td>
                      {current > 1 ? current * 10 - 10 + inx + 1 : inx + 1}
                    </td>
                    <td>{item.titleUz}</td>
                    <td>{item.titleRu}</td>
                    <td>{item.titleEn}</td>
                    <td>{item.descriptionUz}</td>
                    <td>{item.descriptionRu}</td>
                    <td>{item.descriptionEn}</td>
                    <td>{item.createdAt?.slice(0, 10)}</td>
                    <td>
                      <img
                        width={"100%"}
                        src={IMAGE_URL + item.photoPath}
                        alt={item.titleUz}
                      />
                    </td>
                    <td>
                      <div className="d-flex flex-column">
                        <EditOutlined
                          onClick={() => {
                            setIsModalOpen(true);
                            setOptionModal({
                              open: true,
                              type: "edit",
                              item: item,
                            });
                          }}
                          className="fs-4 text-success"
                          style={{ cursor: "pointer" }}
                        />
                        <Popconfirm
                          title="Delete the task"
                          description="Are you sure to delete this task?"
                          onConfirm={() => confirm(item?.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            className="fs-4 text-danger my-3"
                            style={{ cursor: "pointer" }}
                          />
                        </Popconfirm>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="p-5 text-center">
                  <Empty />
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
      <Modal
        title="Yangilik"
        open={optionModal.open}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={[]}
      >
        <NewsEditAddModal
          optionModal={optionModal}
          setOptionModal={setOptionModal}
        />
      </Modal>
    </div>
  );
};
