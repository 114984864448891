import React, { useEffect, useState } from "react";
import { EyeFilled } from "@ant-design/icons";
import { DatePicker, Empty, Modal, Pagination, Spin, message } from "antd";
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { orderList } from "../../api/api";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const OrderOfSeedlings = () => {
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const navigate = useNavigate();
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  useEffect(() => {
    setLoading(true);
    try {
      orderList(10, current, searchWord, date)
        .then((res) => {
          console.log("🚀 ~ file: index.jsx:50 ~ .then ~ res:", res.data);
          setData(res.data?.data?.items);
          setPagination(res?.data?.data?.meta);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, date, searchWord]);

  // filter date
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString);
  };

  // on search
  const onSearch = (value) => setsearchWord(value);
  const { size, page, totalCount } = pagination;

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between">
          <h3 className="mb-3">Ko'chat buyurtmalar</h3>
          <div className="d-flex align-items-center justify-content-center">
            <Search
              placeholder="ismi bo'yich qidirish"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
              allowClear
            />
            <RangePicker
              format={dateFormat}
              onChange={onChangeDate}
              className="mx-2"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "7%" }} scope="col">
                  Fish
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Manzili
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Telefon raqami
                </th>
                <th style={{ width: "3%" }} scope="col">
                  Buyurtma qilingan ko'chatlar
                </th>
              </tr>
            </thead>
            <tbody className="w-100">
              {loading ? (
                <div className="p-5 text-center">
                  <Spin />
                </div>
              ) : data?.length > 0 ? (
                data.map((item) => (
                  <tr key={item?.id}>
                    <td>{item?.fio}</td>
                    <td>{item?.address}</td>
                    <td>{item?.phoneNumber}</td>
                    <td>
                      <ul classname="list-group list-group-flush">
                        {item?.orderItems?.map((service) => (
                          <li classname="list-group-item">
                            <ul>
                              <li>Nomi: {service?.name}</li>
                              <li>Soni: {service?.count}</li>
                            </ul>
                            <hr />
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))
              ) : (
                <div
                  style={{
                    padding: "40px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Empty />
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
    </div>
  );
};
