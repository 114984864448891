import React, { useState } from "react";
import { Modal } from "antd";

const data = [
  {
    id: 1,
    titleUz: "Title uz",
    titleRu: "Title ru",
    titleEn: "Title en",
    nameUz:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. uz",
    nameRu:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum.",
    nameEn:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. en",
    date: new Date().getFullYear(),
    image:
      "https://images.immediate.co.uk/production/volatile/sites/10/2021/03/2048x1365-Pilea-Peperomioides-SEO-GettyImages-1225860485-79b134d.jpg?quality=90&webp=true&resize=940,627",
  },
  {
    id: 2,
    titleUz: "Title uz",
    titleRu: "Title ru",
    titleEn: "Title en",
    nameUz:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. uz",
    nameRu:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum.",
    nameEn:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. en",
    date: new Date().getFullYear(),
    image:
      "https://images.immediate.co.uk/production/volatile/sites/10/2021/03/2048x1365-Pilea-Peperomioides-SEO-GettyImages-1225860485-79b134d.jpg?quality=90&webp=true&resize=940,627",
  },
  {
    id: 3,
    titleUz: "Title uz",
    titleRu: "Title ru",
    titleEn: "Title en",
    nameUz:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. uz",
    nameRu:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum.",
    nameEn:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. en",
    date: new Date().getFullYear(),
    image:
      "https://images.immediate.co.uk/production/volatile/sites/10/2021/03/2048x1365-Pilea-Peperomioides-SEO-GettyImages-1225860485-79b134d.jpg?quality=90&webp=true&resize=940,627",
  },
  {
    id: 4,
    titleUz: "Title uz",
    titleRu: "Title ru",
    titleEn: "Title en",
    nameUz:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. uz",
    nameRu:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum.",
    nameEn:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. en",
    date: new Date().getFullYear(),
    image:
      "https://images.immediate.co.uk/production/volatile/sites/10/2021/03/2048x1365-Pilea-Peperomioides-SEO-GettyImages-1225860485-79b134d.jpg?quality=90&webp=true&resize=940,627",
  },
  {
    id: 5,
    titleUz: "Title uz",
    titleRu: "Title ru",
    titleEn: "Title en",
    nameUz:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. uz",
    nameRu:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum.",
    nameEn:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. en",
    date: new Date().getFullYear(),
    image:
      "https://images.immediate.co.uk/production/volatile/sites/10/2021/03/2048x1365-Pilea-Peperomioides-SEO-GettyImages-1225860485-79b134d.jpg?quality=90&webp=true&resize=940,627",
  },
  {
    id: 6,
    titleUz: "Title uz",
    titleRu: "Title ru",
    titleEn: "Title en",
    nameUz:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. uz",
    nameRu:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum.",
    nameEn:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore commodi ipsa harum excepturi placeat saepe debitis suscipit omnis? Accusantium voluptate odio mollitia quod reiciendis quam iure adipisci, nesciunt eos eum. en",
    date: new Date().getFullYear(),
    image:
      "https://images.immediate.co.uk/production/volatile/sites/10/2021/03/2048x1365-Pilea-Peperomioides-SEO-GettyImages-1225860485-79b134d.jpg?quality=90&webp=true&resize=940,627",
  },
];

export const OrderOfSeedlingsDetails = () => {
  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between">
          <h3 className="mb-3">Buyurtma qilingan ko'chatlar ro'yxati</h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Buyurtma beruvchining ma'lumotlari</h4>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th style={{width: "50%"}}scope="row">FISH</th>
                <th>Mark Sukerberg</th>
              </tr>
              <tr>
                <th style={{width: "50%"}}scope="row">Telefon raqami</th>
                <th>+998 12 345 67 89</th>
              </tr>
              <tr>
                <th style={{width: "50%"}}scope="row">Manzil</th>
                <th>Toshkent, Yunusobod</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        {data.map((item) => (
          <div className="col-6">
            <div class="card mb-3 shadow">
              <div class="row g-0">
                <div class="col-md-4">
                  <img
                    src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20190710102234/download3.png"
                    class="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p>
                    <span class="badge bg-success fs-6"> Miqdori: 50 dona</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
