import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, DatePicker, Modal, Pagination, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import { NewsEditAddModal } from "../../components/newsEditAddModal";
import Search from "antd/es/input/Search";
import { SeedlingsEditAddModal } from "../../components/seedlingsEditAddModal";
import { SeedlingsTypeEditAddModal } from "../../components/seedlingsTypeEditAddModal";
import { seedlingsTypeDelete, seedlingsTypeGet, servicesDelete, servicesGet } from "../../api/api";
import { ServicesEditAddModal } from "../../components/servicesEditAddModal";

export const Services = () => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  // edit and add modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionModal, setOptionModal] = useState({
    open: false,
    type: "",
    item: {},
  });

  useEffect(() => {
    try {
      servicesGet(10, current, date, searchWord)
        .then((res) => {
          setData(res.data?.data?.items);
          console.log("res.data?.data?.items", res.data?.data?.items);
          setPagination(res?.data?.data?.meta);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, update, optionModal, date, searchWord]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setOptionModal({
      open: false,
    });
  };

  // delete
  const confirm = (id) => {
    try {
      servicesDelete(id)
        .then((res) => {
          message.success("Muvaffaqiyatli o'chirildi");
          setUpdate((prev) => !prev);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const cancel = (e) => {
    // console.log(e);
    // message.error('Click on No');
  };

  // filter date
  const onChangeDate = (date, dateString) => {
    console.log(dateString);
    setDate(dateString);
  };

  // on search
  const onSearch = (value) => setsearchWord(value);

  const { size, page, totalCount } = pagination;

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between">
          <h3 className="mb-3">Xizmatlar</h3>
          <div className="d-flex align-items-center justify-content-center">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
              allowClear
              className="me-2"
            />
            <Button
              type="primary"
              onClick={() => {
                setOptionModal({
                  open: true,
                  type: "add",
                  item: {},
                });
              }}
            >
              + Qo'shish
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha uz
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha ru
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha en
                </th>
                <th style={{ width: "3%" }} scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.titleUz}</td>
                  <td>{item.titleRu}</td>
                  <td>{item.titleEn}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <EditOutlined
                        onClick={() => {
                          setIsModalOpen(true);
                          setOptionModal({
                            open: true,
                            type: "edit",
                            item: item,
                          });
                        }}
                        className="fs-4 text-success me-2"
                        style={{ cursor: "pointer" }}
                      />
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        onConfirm={()=>confirm(item?.id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined
                          className="fs-4 text-danger"
                          style={{ cursor: "pointer" }}
                        />
                      </Popconfirm>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination current={current} onChange={onChange} total={totalCount} />
        </div>
      </div>
      <Modal
        title="Xizmatlar"
        open={optionModal.open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <ServicesEditAddModal
          optionModal={optionModal}
          setOptionModal={setOptionModal}
        />
      </Modal>
    </div>
  );
};
