import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { seedlingsTypeCreate, seedlingsTypeEdit } from "../../api/api";

export const SeedlingsTypeEditAddModal = ({ optionModal, setOptionModal }) => {
  const [form] = useForm();
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(optionModal);
  useEffect(() => {
    if (optionModal?.type === "edit") {
      form.setFieldsValue(optionModal?.item);
    } else {
      form.resetFields();
    }
  }, [optionModal]);

  const onFinish = (values) => {
    setLoading(true);
    try {
      if (optionModal?.type == "edit") {
        seedlingsTypeEdit(optionModal?.item?.id, values)
          .then((res) => {
            message.success("Muvaffaqiyatli o'zgartirildi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        seedlingsTypeCreate(values)
          .then((res) => {
            message.success("Muvaffaqiyatli saqlandi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onTypeChange = (value) => {
    setType(value);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Form.Item
        label="Name uz"
        name="titleUz"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Name en"
        name="titleEn"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Name ru"
        name="titleRu"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        label="Guruhi"
        name="nameRu"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Select
          defaultValue="olma"
          style={{
            width: "100%",
          }}
          // onChange={handleChange}
          options={[
            {
              value: "olma",
              label: "Olma",
            },
            {
              value: "shaftoli",
              label: "Shaftoli",
            },
            {
              value: "anor",
              label: "Anor",
            },
            {
              value: "Boshqa",
              label: "Boshqa",
            },
          ]}
        />
      </Form.Item> */}

      <Form.Item className="d-flex justify-content-end">
        <Button
          onClick={() => setOptionModal({ open: false })}
          type="default"
          htmlType="reset"
          className="me-2"
        >
          Yopish
        </Button>
        <Button loading={loading} type="primary" htmlType="submit">
          {optionModal?.type === "edit" ? "Yangilash" : "Saqlash"}
        </Button>
      </Form.Item>
    </Form>
  );
};
