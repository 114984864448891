import instance from "../axios/axios_config";
const formData = {
  "Content-Type": "multipart/form-data",
};

const login = (data) => {
  return instance({
    url: "/auth/login",
    method: "post",
    data: data,
  });
};

// news
const newsGet = (size, page, from_to, search) => {
  return instance({
    url:
      `admin/information?type=NEWS&page=${page}&size=${size}` +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length > 0 ? `&search=` + search : ""),
    method: "get",
  });
};

const newsCreate = (data) => {
  return instance({
    url: `admin/information/add`,
    method: "post",
    data: data,
    headers: formData,
  });
};

const newsDelete = (id) => {
  return instance({
    url: `admin/information/delete/${id}`,
    method: "delete",
  });
};

const newsEdit = (id, data) => {
  return instance({
    url: `admin/information/edit/${id}`,
    method: "put",
    data,
    headers: formData,
  });
};

// announcements
const announcmentsGet = (size, page, from_to, search) => {
  return instance({
    url:
      `admin/information?type=ANNOUNCEMENT&page=${page}&size=${size}` +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length > 0 ? `&search=` + search : ""),
    method: "get",
  });
};

const announcmentsCreate = (data) => {
  return instance({
    url: `admin/information/add`,
    method: "post",
    data: data,
    headers: formData,
  });
};

const announcmentsDelete = (id) => {
  return instance({
    url: `admin/information/delete/${id}`,
    method: "delete",
  });
};

const announcmentsEdit = (id, data) => {
  return instance({
    url: `admin/information/edit/${id}`,
    method: "put",
    data,
    headers: formData,
  });
};

// publication
const publicationsGet = (size, page, type, search, from_to, subType) => {
  return instance({
    url:
      `admin/publication?page=${page}&size=${size}` +
      (type?.length !== 1 ? `&type=` + type : "") +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length > 0 ? `&search=` + search : "") +
      (subType?.length > 0 ? `&subType=` + subType : ""),
    method: "get",
  });
};

const publicationsCreate = (data) => {
  return instance({
    url: `admin/publication/add`,
    method: "post",
    data: data,
    headers: formData,
  });
};

const publicationsDelete = (id) => {
  return instance({
    url: `admin/publication/delete/${id}`,
    method: "delete",
  });
};

const publicationsEdit = (id, data) => {
  return instance({
    url: `admin/publication/edit/${id}`,
    method: "put",
    data,
    headers: formData,
  });
};

// seedlings type
const seedlingsTypeGet = (size, page, type, search) => {
  return instance({
    url:
      `admin/seedling-type?page=${page}&size=${size}` +
      (type?.length !== 1 ? `&type=` + type : "") +
      (search?.length > 0 ? `&search=` + search : ""),
    method: "get",
  });
};

const seedlingsTypeCreate = (data) => {
  return instance({
    url: `admin/seedling-type/add`,
    method: "post",
    data: data,
  });
};

const seedlingsTypeDelete = (id) => {
  return instance({
    url: `admin/seedling-type/delete/${id}`,
    method: "delete",
  });
};

const seedlingsTypeEdit = (id, data) => {
  return instance({
    url: `admin/seedling-type/edit/${id}`,
    method: "put",
    data,
  });
};

const seedlingsTypeGetForSelect = () => {
  return instance({
    url: `/seedling-type/select`,
    method: "get",
  });
};

// seedlings
const seedlingsGet = (size, page, type, search, from_to) => {
  return instance({
    url:
      `admin/seedling?page=${page}&size=${size}` +
      (type?.length > 0 ? `&type=` + type : "") +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length > 0 ? `&search=` + search : ""),
    method: "get",
  });
};

const seedlingsCreate = (data) => {
  return instance({
    url: `admin/seedling/add`,
    method: "post",
    data: data,
    headers: formData,
  });
};

const seedlingsDelete = (id) => {
  return instance({
    url: `admin/seedling/delete/${id}`,
    method: "delete",
  });
};

const seedlingsEdit = (id, data) => {
  return instance({
    url: `admin/seedling/edit/${id}`,
    method: "put",
    data,
    headers: formData,
  });
};

// New species
const speciesGet = (size, page, type, search, from_to) => {
  return instance({
    url:
      `admin/specie?page=${page}&size=${size}` +
      (type?.length > 0 ? `&type=` + type : "") +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length > 0 ? `&search=` + search : ""),
    method: "get",
  });
};

const speciesCreate = (data) => {
  return instance({
    url: `admin/specie/add`,
    method: "post",
    data: data,
    headers: formData,
  });
};

const speciesDelete = (id) => {
  return instance({
    url: `admin/specie/delete/${id}`,
    method: "delete",
  });
};

const speciesEdit = (id, data) => {
  return instance({
    url: `admin/specie/edit/${id}`,
    method: "put",
    data,
    headers: formData,
  });
};

// services
const servicesGet = (size, page, type, search) => {
  return instance({
    url:
      `admin/service?page=${page}&size=${size}` +
      (type?.length !== 1 ? `&type=` + type : "") +
      (search?.length > 0 ? `&search=` + search : ""),
    method: "get",
  });
};

const servicesCreate = (data) => {
  return instance({
    url: `admin/service/add`,
    method: "post",
    data: data,
  });
};

const servicesDelete = (id) => {
  return instance({
    url: `admin/service/delete/${id}`,
    method: "delete",
  });
};

const servicesEdit = (id, data) => {
  return instance({
    url: `admin/service/edit/${id}`,
    method: "put",
    data,
  });
};

const servicesGroupSelectGet = () => {
  return instance({
    url: `/service-group`,
    method: "get",
  });
};

// appeals
const appealsGet = (size, page, search, from_to) => {
  return instance({
    url:
      `admin/appeal?page=${page}&size=${size}` +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length ? `&search=` + search : ""),
    method: "get",
  });
};

// order list
const orderList = (size, page, search, from_to) => {
  return instance({
    url:
      `admin/order?page=${page}&size=${size}` +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length ? `&search=` + search : ""),
    method: "get",
  });
};

// order services list
const orderServicesList = (size, page, search, from_to) => {
  return instance({
    url:
      `admin/service-apply?page=${page}&size=${size}` +
      (from_to[0]?.length > 0 ? `&from_to=` + from_to : "") +
      (search?.length ? `&search=` + search : ""),
  });
};

export {
  login,
  newsGet,
  newsCreate,
  newsDelete,
  newsEdit,
  announcmentsGet,
  announcmentsCreate,
  announcmentsDelete,
  announcmentsEdit,
  publicationsGet,
  publicationsCreate,
  publicationsDelete,
  publicationsEdit,
  seedlingsTypeGet,
  seedlingsTypeCreate,
  seedlingsTypeDelete,
  seedlingsTypeEdit,
  seedlingsTypeGetForSelect,
  seedlingsGet,
  seedlingsCreate,
  seedlingsDelete,
  seedlingsEdit,
  speciesGet,
  speciesCreate,
  speciesDelete,
  speciesEdit,
  servicesGet,
  servicesCreate,
  servicesDelete,
  servicesEdit,
  servicesGroupSelectGet,
  appealsGet,
  orderList,
  orderServicesList
};
