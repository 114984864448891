import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { publicationsCreate, publicationsEdit } from "../../api/api";
const { Option } = Select;

const articles = [
  { id: 1, name: "Xorijiy", value: "foregin" },
  { id: 2, name: "Mahalliy", value: "local" },
];

const avtoreferats = [
  { id: 1, name: "Phd", value: "phd" },
  { id: 2, name: "Dsc", value: "dsc" },
];

export const PublicationEditAddModal = ({ optionModal, setOptionModal }) => {
  const [form] = useForm();
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(optionModal);
  useEffect(() => {
    if (optionModal?.type === "edit") {
      form.setFieldsValue(optionModal?.item);
    } else {
      form.resetFields();
    }
  }, [optionModal]);

  const onFinish = (values) => {
    let data = new FormData();
    for (const key in values) {
      if (key === "file") {
        values?.file &&
          data.append(key, values?.file?.file?.originFileObj);
      } else {
        data.append(key, values[key]);
      }
    }
    setLoading(true);
    try {
      if (optionModal?.type == "edit") {
        publicationsEdit(optionModal?.item?.id, data)
          .then((res) => {
            message.success("Muvaffaqiyatli o'zgartirildi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        publicationsCreate(data)
          .then((res) => {
            message.success("Muvaffaqiyatli saqlandi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onTypeChange = (value) => {
    setType(value);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <div className="row">
        <div className="col-4">
          <Form.Item
            label="Name uz"
            name="authorUz"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Name en"
            name="authorEn"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Name ru"
            name="authorRu"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Title uz"
            name="titleUz"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Title en"
            name="titleEn"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Title ru"
            name="titleRu"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif uz"
            name="descriptionUz"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif en"
            name="descriptionEn"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif ru"
            name="descriptionRu"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>

        <div className="col-4">
          <Form.Item
            name="type"
            label="Turi"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select a option and change input text above"
              onChange={onTypeChange}
              allowClear
            >
              <Option value="ARTICLES">Maqolalar</Option>
              <Option value="ABSTRACTS">Avtoreferat</Option>
              <Option value="MONOGRAPHS">Monogramma</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item name="subType" label="Turi">
            <Select
              placeholder="Select a option and change input text above"
              // onChange={onGenderChange}
              allowClear
            >
              {(type === "ARTICLES"
                ? articles
                : type === "ABSTRACTS"
                ? avtoreferats
                : []
              ).map((item) => (
                <Option key={item?.id} value={item.value}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item name="file" label="Fayl">
            <Upload
              customRequest={({ onSuccess }) => {
                onSuccess("ok");
              }}
              maxCount={1}
              // accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.pdf,application/pdf"
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </div>
      </div>

      <Form.Item className="d-flex justify-content-end">
        <Button
          onClick={() => setOptionModal({ open: false })}
          type="default"
          htmlType="reset"
          className="me-2"
        >
          Yopish
        </Button>
        <Button loading={loading} type="primary" htmlType="submit">
          {optionModal?.type === "edit" ? "Yangilash" : "Saqlash"}
        </Button>
      </Form.Item>
    </Form>
  );
};
