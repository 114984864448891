import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import {
  seedlingsCreate,
  seedlingsEdit,
  seedlingsTypeGetForSelect,
} from "../../api/api";

export const SeedlingsEditAddModal = ({ optionModal, setOptionModal }) => {
  const [form] = useForm();
  const [type, setType] = useState("");
  const [selectType, setSelectType] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(optionModal);
  useEffect(() => {
    if (optionModal?.type === "edit") {
      form.setFieldsValue(optionModal?.item);
    } else {
      form.resetFields();
    }
  }, [optionModal]);

  useEffect(() => {
    try {
      seedlingsTypeGetForSelect()
        .then((res) => {
          setSelectType(res.data?.data);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onFinish = (values) => {
    let data = new FormData();
    for (const key in values) {
      if (key === "file") {
        values?.file?.fileList[0] &&
          data.append(key, values?.file?.fileList[0]?.originFileObj);
      } else {
        data.append(key, values[key]);
      }
    }
    console.log("dsadasda", data);
    setLoading(true);
    try {
      if (optionModal?.type == "edit") {
        seedlingsEdit(optionModal?.item?.id, data)
          .then((res) => {
            message.success("Muvaffaqiyatli o'zgartirildi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        seedlingsCreate(data)
          .then((res) => {
            message.success("Muvaffaqiyatli saqlandi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onTypeChange = (value) => {
    setType(value);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <div className="row">
        <div className="col-4">
          <Form.Item
            label="Name uz"
            name="titleUz"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Name en"
            name="titleEn"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Name ru"
            name="titleRu"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif uz"
            name="descriptionUz"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          {" "}
          <Form.Item
            label="Tasnif en"
            name="descriptionEn"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif ru"
            name="descriptionRu"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Necha yilligi"
            name="residenceTime"
            rules={[
              {
                required: true,
                message: "Iltimos ma'lumot kiriting!",
              },
            ]}
          >
            <Select
              defaultValue="1 yillik"
              style={{
                width: "100%",
              }}
              // onChange={handleChange}
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
                {
                  value: "moreYear",
                  label: "Ko'p yillik",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Guruhi"
            name="seedlingTypeId"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Select
              // defaultValue="olma"
              style={{
                width: "100%",
              }}
              // onChange={handleChange}
              options={selectType?.map((item) => {
                return {
                  value: item?.id,
                  label: item?.name,
                };
              })}
            />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Rasm yuklash"
            name={"file"}
            // valuePropName="image"
            // getValueFromEvent={normFile}
          >
            <Upload
              customRequest={({ onSuccess }) => {
                onSuccess("ok");
              }}
              maxCount={1}
              listType="picture-card"
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Rasm yuklash
                </div>
              </div>
            </Upload>
          </Form.Item>
        </div>
      </div>

      <Form.Item className="d-flex justify-content-end">
        <Button
          onClick={() => setOptionModal({ open: false })}
          type="default"
          htmlType="reset"
          className="me-2"
        >
          Yopish
        </Button>
        <Button loading={loading} type="primary" htmlType="submit">
          {optionModal?.type === "edit" ? "Yangilash" : "Saqlash"}
        </Button>
      </Form.Item>
    </Form>
  );
};
