import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { newsCreate, newsEdit } from "../../api/api";

export const NewsEditAddModal = ({ optionModal, setOptionModal }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (optionModal?.type === "edit") {
      form.setFieldsValue(optionModal?.item);
    } else {
      form.resetFields();
    }
  }, [optionModal]);

  const onFinish = (values) => {
    let data = new FormData();
    data.append("type", "NEWS");
    for (const key in values) {
      if (key == "file") {
        console.log(values?.file?.file?.originFileObj);
        values["file"] && data.append(key, values?.file?.file?.originFileObj);
      } else {
        console.log(key);
        data.append(key, values[key]);
      }
    }
    setLoading(true);
    try {
      if (optionModal?.type == "edit") {
        newsEdit(optionModal?.item?.id, data)
          .then((res) => {
            message.success("Muvaffaqiyatli o'zgartirildi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        newsCreate(data)
          .then((res) => {
            message.success("Muvaffaqiyatli saqlandi!");
            form.resetFields();
            setOptionModal({
              open: false,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <div className="row">
        <div className="col-4">
          <Form.Item
            label="Name uz"
            name="titleUz"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Name en"
            name="titleEn"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Name ru"
            name="titleRu"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif uz"
            name="descriptionUz"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif en"
            name="descriptionEn"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif ru"
            name="descriptionRu"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item label="Rasm yuklash" name={"file"}>
            <Upload
              customRequest={({ onSuccess }) => {
                onSuccess("ok");
              }}
              accept="image/*"
              maxCount={1}
              listType="picture-card"
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Rasm yuklash
                </div>
              </div>
            </Upload>
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item label="Yangilik vaqtini tanlang" name={"releaseDate"}>
            {/* <DatePicker format="YYYY-MM-DD" /> */}
            <input className="form-control" type="date" name="" id="" />
          </Form.Item>
        </div>
      </div>

      {/* <Form.Item
        label="Turi"
        name="type"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <TextArea rows={2} />
      </Form.Item> */}

      <Form.Item className="d-flex justify-content-end">
        <Button
          onClick={() => setOptionModal({ open: false })}
          type="default"
          htmlType="reset"
          className="me-2"
        >
          Yopish
        </Button>
        <Button loading={loading} type="primary" htmlType="submit">
          {optionModal?.type === "edit" ? "Yangilash" : "Saqlash"}
        </Button>
      </Form.Item>
    </Form>
  );
};
